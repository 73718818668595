import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Spend Less Time on Veteran Resumes and More Time on Getting Life Situated",
  "author": "Anthony",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Military Transition"],
  "slug": "spend-less-time-on-veteran-resumes-and-more-on-getting-life-situated",
  "draft": false,
  "meta_title": "Spend Less Time on Veteran Resumes and More Time on Getting Life Situated",
  "cover": "../../images/wp_blog_images/man-people-space-desk-large.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Veteran resume writing can be tedious. It can also be expensive if you outsource, upwards to $2,500 for custom written veteran resumes. If you google "tips on preparing a veteran resume" you’ll find thousands of conflicting pieces of advice. If you ask a buddy for help, they’ll more than likely have gone through the same revolving door of resources with little substantial, meaningful outcome.`}</p>
    <p>{`So where is the real start and how do you know the best way to spend the right amount of time on a resume without letting it overshadow the rest of the checklist as you transition from active duty status?`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "890px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.5625%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAADBP/aAAwDAQACEAMQAAABZdyHTvIWIf8A/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIRE//aAAgBAQABBQJVj55gT0MS3JtuG//EABcRAAMBAAAAAAAAAAAAAAAAAAEDECH/2gAIAQMBAT8BZpn/xAAYEQEAAwEAAAAAAAAAAAAAAAABAgMQIf/aAAgBAgEBPwGsYnc//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERIQIjQf/aAAgBAQAGPwIh8KIFNj14n//EABwQAQACAgMBAAAAAAAAAAAAAAEAMREhUWFxgf/aAAgBAQABPyE8VKR0OYu0SJGlxBDH2IxfTc//2gAMAwEAAgADAAAAEFwv/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhMf/aAAgBAwEBPxBhA8sv/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAgEBPxBg9Njf/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBgbH/2gAIAQEAAT8Qo8cjVNRESuOEQXBg8iojFWsSQHhZ6lBcMaKfk//Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "man people space desk large",
        "title": "man people space desk large",
        "src": "/static/644ad5bebbb5f701feba7495c672dd4d/7f80b/man-people-space-desk-large.jpg",
        "srcSet": ["/static/644ad5bebbb5f701feba7495c672dd4d/cb69c/man-people-space-desk-large.jpg 320w", "/static/644ad5bebbb5f701feba7495c672dd4d/c08c5/man-people-space-desk-large.jpg 640w", "/static/644ad5bebbb5f701feba7495c672dd4d/7f80b/man-people-space-desk-large.jpg 890w"],
        "sizes": "(max-width: 890px) 100vw, 890px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`Your veteran resume should be a quick capture of your organizational skills, your preparedness, your experience, and function as your vessel to market yourself. It is an example of your value as an employee to a potential employer and your ability to thrive in any job setting. Sounds easy, right? Truth: It’s hard to explain 5, 10, 15, 30 years of experience in ANY job in a concise, organized manner. Add in the expertise and intensive training and job experiences of soldiers, sailors, or marines and you’ve taken job translation to a whole new level.`}</p>
    <p><strong parentName="p">{`SO WHAT'S THE ANSWER?`}</strong></p>
    <p>{`This sub-standard experience for veteran resumes was the catalyst for Purepost. Our CEO and Co-founder, Anthony Garcia, experienced exactly what most veterans experience: frustration and time suck during a very critical transition period. He had a fellow veteran and his father assist in translating his military service to private-sector understanding. He admits he would have been completely lost without their assistance. The process involved digging up old OERs/NCOERs, looking at past assignments, and focused reflection on career experience.`}</p>
    <p>{`As he reached out and started talking to others transitioning about this experience, he noted that assistance from other warriors who have made a similar transition successfully provides validation that what you did while serving can translate to the private sector. It helps you to understand how you can contribute after service and what possible gaps need to be filled through education and training to be successful. But all of that could be done for service members in a more productive and automated fashion.`}</p>
    <p>{`Years of behavioral science and skill-based translations have fueled Pirepost’s automated resume service. Kickstarting your transition with an automated resume will save you time and resources and ultimately connect you into the network of job opportunities you need to be successful. Spend time on the family transition, the medical paperwork, the interviews and job opportunities. Don’t waste unneeded time on rigorous resume work when the muscle has been done for you.`}</p>
    <p>{`Begin your translation experience `}<a parentName="p" {...{
        "href": "http://www.purepost.co/"
      }}>{`here`}</a>{` today - with a Purepost professional profile and a free resume.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      